import type {NavbarItem} from "~/types/base/NavbarItem";
import type {StatusType} from "~/types/StatusType";
import type {FormErrorEvent} from "#ui/types";
import {busIcon, hotelIcon, tourIcon, trainIcon} from "~/utils/icon-utils";

export const getSitePublic = () => useRuntimeConfig().public

export const getNavbar = (): NavbarItem[] => {
    return [
        {
            name: 'flight',
            fa_name: 'پرواز',
            icon: planeIcon(),
            slug: null,
            options: [
                {
                    name: 'domestic flight',
                    fa_name: 'پرواز داخلی',
                    slug: '/flight',
                    active: true
                },
                {
                    name: 'international flight',
                    fa_name: 'پرواز خارجی',
                    slug: '/i-flight',
                    active: false
                }
            ]
        },
        {
            name: 'hotel',
            fa_name: 'هتل',
            icon: hotelIcon(),
            slug: null,
            options: [
                {
                    name: 'domestic hotel',
                    fa_name: 'هتل داخلی',
                    slug: '/hotel',
                    active: false
                },
                {
                    name: 'international flight',
                    fa_name: 'هتل خارجی',
                    slug: '/i-hotel',
                    active: false
                }
            ]
        },
        {
            name: 'tour',
            fa_name: 'تور',
            icon: tourIcon(),
            slug: null,
            options: [
                {
                    name: 'tour',
                    fa_name: 'پرواز + هتل',
                    slug: '/tour',
                    active: false
                },
                {
                    name: 'group tours',
                    fa_name: 'تور گروهی',
                    slug: '/g-tour',
                    active: false
                }
            ]
        },
        {
            name: 'train',
            fa_name: 'قطار',
            icon: trainIcon(),
            slug: '/train',
            active: false,
            options: []
        },
        {
            name: 'bus',
            fa_name: 'اتوبوس',
            icon: busIcon(),
            slug: '/bus',
            active: false,
            options: []
        }
    ]
}

export const allStatusType = (): StatusType[] => ([
    {
        name: 'active',
        fa_name: 'فعال',
        code: 1
    },
    {
        name: 'inactive',
        fa_name: 'غیر فعال',
        code: 0
    }
])

export const onErrorForm = async (event: FormErrorEvent) => {
    navigator.vibrate(200)
    console.log(event.errors)
    if (event?.errors?.[0]?.id) {
        const element = document.getElementById(event.errors[0].id)
        element?.focus()
        element?.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
}

export function formatTimeString(timeString: string, persian = true): string {
    const [hours, minutes] = timeString.split(':').map(Number);

    let formattedTime = '';

    if (hours > 0) {
        formattedTime += persian ? `${hours} ساعت` : `${hours} hours`;
    }

    if (minutes > 0) {
        if (formattedTime) formattedTime += persian ? ' و ' : ' and ';
        formattedTime += persian ? `${minutes} دقیقه` : `${minutes} minutes`;
    }

    return formattedTime || (persian ? 'نا مشخص' : 'Not specified');
}


export const getTimeDifference = (time1: string, time2: string | null = null): string => {
    if (!time1) return '00:00';
    if (!time2) return '01:45';

    const parseTime = (time: string) => {
        const [hours, minutes] = time.split(':').map(Number);
        return hours * 60 + minutes; // Convert to total minutes
    };

    const time1Minutes = parseTime(time1);
    let time2Minutes = time2 ? parseTime(time2) : parseTime('01:45');

    // Handle cases where time2 is earlier than time1 (crossing midnight)
    if (time2 && time2Minutes < time1Minutes) {
        time2Minutes += 24 * 60; // Add 24 hours
    }

    const difference = Math.abs(time2Minutes - time1Minutes);

    // Convert back to hours and minutes
    const hours = Math.floor(difference / 60).toString().padStart(2, '0');
    const minutes = (difference % 60).toString().padStart(2, '0');

    return `${hours}:${minutes}`;
};
export const formatHHii = (time: string): string => time.split(':').slice(0, 2).join(':'); // Extract HH and ii (minutes)

export const getUtmParams = (utm: { source?: string; medium?: string; campaign?: string }) => {
    const params = new URLSearchParams();

    if (utm.source) params.set("utm_source", utm.source);
    if (utm.medium) params.set("utm_medium", utm.medium);
    if (utm.campaign) params.set("utm_campaign", utm.campaign);

    return params.toString(); // خروجی: `utm_source=nuxt&utm_medium=redirect&utm_campaign=flight`
};
